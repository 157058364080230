<template>
    <div><ul class="tabs">
        <li :key="item.key" v-for="(item) in tabsList" @click="setActiveName(item.key)" class="tabItem"
            :class="{ active: activeName === item.key }">{{ item.val }}</li>
        </ul>
    <div class="handle-btn">
        <button @click="toRegister()">没有账号？立即注册</button>
        <button @click="toLogin()">已注册，立即登录</button>
        <p v-if="activeName=='user'" class="tipwes">未注册手机号验证后自动创建账号</p>
        <button v-if="activeName!='user'" class="try" @click="goAdmin">先去试用</button>
    </div></div>
</template>
<script>
import {jumpRegister,jumpTry} from '@/utils/getBranchDomain.js'

export default {
    props:{
        tabsList:{
            type:Array,
            default:()=>[]
        },
        activeName:{
            type:String,
            default:'govt'
        }
    },
    methods: {
        toRegister(){
            jumpRegister(this.activeName)
        },
        toLogin(){
            this.$router.push({
                path:'/login',
                query:{
                    from:this.activeName
                }
            })
        },
        goAdmin(){
            jumpTry(this.activeName);
        },
        // tab 切换
        setActiveName(val) {
            this.$emit('change',val)
        },
    },
}
</script>
<style lang="scss" scoped>
	.tabs {
        display: flex;
        margin-bottom: 32px;
        .tabItem {
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #6D6D6D;
            line-height: 28px;
            cursor: pointer;
            padding-left: 48px;
            position: relative;
            &::after{
                position: absolute;
                top:50%;
                transform: translateY(-50%);
                left:24px;
                width:2px;
                height:14px;
                background: #6D6D6D;
                content:'';
            }
            &:nth-child(1){
                padding-left: 0;
                &::after{
                    width: 0;
                }
            }
            &.active {
                color: $WDPrimaryColor;
            }
        }
    }
  .handle-btn{
    .tipwes{
        text-align: center;
        color:$WDPrimaryColor;
        font-weight: 550;
    }
    button{
      border:none;
      appearance: none;
      box-shadow:none;
      width: 526px;
      height: 68px;
      background: #FFFFFF;
      box-shadow: 1px 0px 6px 0px rgba(188,188,188,0.5);
      border-radius: 4px;
      text-align:center;
      line-height:68px;
      margin-bottom:22px;
      color:#6D6D6D;
      font-size:16px;
      font-weight:550;
      &:hover{
        cursor: pointer;
        opacity: .8;
      }
      &.try{
        background:$WDPrimaryColor;
        color:#fff;
        border-radius:32px;
      }
    }
  }
</style>
