var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"staff"},[_c('div',{staticClass:"staff-banner"},[_c('img',{attrs:{"src":"https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/staff/staff-banner.png"}}),_c('div',{staticClass:"handle-btn"},[_c('button',{on:{"click":_vm.toLogin}},[_vm._v("政府/协会管理人员注册/登录")]),_c('button',{on:{"click":_vm.goAdmin}},[_vm._v("政府/协会管理人员试用")])])]),_c('div',{staticClass:"staff-content"},[_c('div',{staticClass:"staff-content-container"},[_c('div',{staticClass:"module1"},[_c('div',{staticClass:"module1-item"},[_vm._m(0),_c('div',{staticClass:"module1-item-right"},[_c('p',[_vm._v("山东应急全员定向安全培训")]),_c('div',[_c('button',{on:{"click":_vm.toView}},[_vm._v("查看培训课程")]),_c('button',{on:{"click":function($event){return _vm.toJump('/staff/orienteering')}}},[_vm._v("详情介绍")])])])]),_c('div',{staticClass:"module1-item"},[_vm._m(1),_c('div',{staticClass:"module1-item-right"},[_c('p',[_vm._v("广西工业风险管理与防范能力建设项目")]),_c('div',[_c('button',{on:{"click":function($event){return _vm.toJump('/staff/other')}}},[_vm._v("详情介绍")])])])])]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)]),_c('img',{staticClass:"bg",attrs:{"src":"https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/staff/staff-bg.png"}})]),_c('el-dialog',{attrs:{"visible":_vm.dialogVisible,"width":"640px","before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',{staticClass:"loginDialog"},[_c('fe-login-register',{attrs:{"activeName":"govt","tabsList":_vm.tabsList}})],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module1-item-left"},[_c('img',{attrs:{"src":"https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/staff/m1-1.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module1-item-left"},[_c('img',{attrs:{"src":"https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/staff/m1-2.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module2 modules"},[_c('p',{staticClass:"title"},[_vm._v("企业注册，组织员工扫码，通过手机或电脑参加培训")]),_c('img',{staticClass:"line",attrs:{"src":"https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/staff/line.png"}}),_c('div',{staticClass:"module2-content"},[_c('div',{staticClass:"module2-left module2-item"},[_c('img',{attrs:{"src":"https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/staff/m2-1.png"}}),_c('p',[_vm._v("企业申请")])]),_c('div',{staticClass:"module2-right module2-item"},[_c('img',{attrs:{"src":"https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/staff/m2-2.png"}}),_c('p',[_vm._v("员工扫码，进入手机/电脑参加学习")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module3 modules"},[_c('p',{staticClass:"title"},[_vm._v("人脸识别，保证本人参培")]),_c('img',{staticClass:"line",attrs:{"src":"https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/staff/line.png"}}),_c('div',{staticClass:"module3-content"},[_c('p',[_vm._v("参加考试或参加培训时，系统通过人脸识别校验当前学员身份；"),_c('br'),_vm._v("同时系统支持记录人脸识别截图，用于数据留存及学习监管。")]),_c('img',{attrs:{"src":"https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/staff/m3-1.png"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module4 modules"},[_c('p',{staticClass:"title"},[_vm._v("培训过程实时监管")]),_c('img',{staticClass:"line",attrs:{"src":"https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/staff/line.png"}}),_c('div',{staticClass:"module4-content"},[_c('img',{attrs:{"src":"https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/staff/m4-1.png"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module5 modules"},[_c('p',{staticClass:"title"},[_vm._v("开展考试，检查培训效果：千人千卷")]),_c('img',{staticClass:"line",attrs:{"src":"https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/staff/line.png"}}),_c('div',{staticClass:"module5-content"},[_c('img',{attrs:{"src":"https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/staff/m5-1.png"}}),_c('div',{staticClass:"module5-content-right"},[_c('div',{staticClass:"content-item"},[_c('p',{staticClass:"title-item"},[_c('i'),_vm._v("随机组卷：")]),_c('p',{staticClass:"content-item"},[_vm._v("随机从指定题库抽题每位考生，题目不同。")])]),_c('div',{staticClass:"content-item"},[_c('p',{staticClass:"title-item"},[_c('i'),_vm._v("顺序随机：")]),_c('p',{staticClass:"content-item"},[_vm._v("试卷设计题型、题目乱序，每道题出现顺序不同。")])]),_c('div',{staticClass:"content-item"},[_c('p',{staticClass:"title-item"},[_c('i'),_vm._v("选项随机：")]),_c('p',{staticClass:"content-item"},[_vm._v("打乱选择题类试题选项顺序，ABCD答案人人不同。")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module6 modules"},[_c('p',{staticClass:"title"},[_vm._v("生成学习档案：区块链技术防信息篡改")]),_c('img',{staticClass:"line",attrs:{"src":"https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/staff/line.png"}}),_c('div',{staticClass:"module6-content"},[_c('div',{staticClass:"text"},[_c('p',[_c('i'),_vm._v("通过手机扫码查看个人学习档案 个人学习档")]),_c('p',[_c('i'),_vm._v("个人学习档案中可查看每次进入课程时的人脸"),_c('br'),_vm._v("识别信息")])]),_c('img',{attrs:{"src":"https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/staff/m6-1.png"}})])])
}]

export { render, staticRenderFns }