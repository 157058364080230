<template>
  <div class="staff">
    <div class="staff-banner">
        <div class="staff-banner-content">
            <p class="title-staff">广西工业风险管理与防范能力建设项目</p>
            <div class="staff-x">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/staff/other1.png">
                <p>   2021年7月，法国德莫斯培训集团中标广西壮族自治区工业风险管理与防范技术援助及能力建设项目。此项目是法国总统马克龙 2019 年访华时与习近平主席签署的中法战略合作项目之一，由德莫斯集团负责引进欧洲标准、应急消防领域专家资源及培训课程，旨在为国家应急消防能力建设引入欧盟标准培训体系，对提升我国工业消防能力具有战略意义。</p>
            </div>
        </div>
    </div>
    <div class="staff-content">
      <div class="staff-content-container">
          <div class="module5 modules">
          <p class="title">2021年伟东云教育集团的全资子公司法国德莫斯培训集团与广西壮族自治区消防救援总队、中机招标公司签署广西工业风险管理与防范能力建设项目咨询服务协议，其中包含四个子任务：</p>
          <div class="module5-content">
            <div class="module5-content-right">
              <div class="item">
                <p class="title-item">任务一</p>
                <p class="content-item">中外工业园区风险防范与管理能力对比分析任务；</p>
              </div>
              <div class="item">
                <p class="title-item">任务二</p>
                <p class="content-item">组织应急管理干部赴国外开展洽谈磋商、学习工业风险预防和事故处置经验，加强能力建设任务；</p>
              </div>
              <div class="item">
                <p class="title-item">任务三</p>
                <p class="content-item">实操实训任务；</p>
              </div>
              <div class="item">
                <p class="title-item">任务四</p>
                <p class="content-item">为广西基层一线消防员提供课程培训。</p>
              </div>
            </div>
          </div>
        </div>
         <div class="module6 modules">
          <p class="title">      在已实施的培训中，赴法参训指战员积极分享探讨法国有益做法，洋为中用，促进了灭火救援攻坚和队伍管理水平。同时增强了广西自治区消防救援人员对外交往、经验交流的自信心，极大地调动了宣传中国消防的先进做法，发出中国消防声音的积极性。
      此项目作为示范性案例，不仅是中法友好的硕果，更为广西筹建国际救援队服务国家周边外交的重要使命提供了智力支持。</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return{
      
    }
  },
  methods:{
   
  }
}
</script>
<style lang="scss" scoped>
.staff-banner{
    background:url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/staff/other-banner-bg.png') no-repeat center center;
    padding: 60px 0 96px;
    background-size:cover;
    .staff-banner-content{
        width: 1200px;
        height: 377px;
        background: rgba(255,59,41,0.59);
        margin: 0 auto ;
        padding:45px 55px;
        box-sizing:border-box;
        color:#fff;
        .staff-x{
            display:flex;
            p{
                font-weight:500;
                margin-left:28px;
                padding-top:16px;
                font-size:22px;
                line-height:36px;
            }
        }
        .title-staff{
            font-size:28px;
            line-height:40px;
            margin-bottom:12px;
            font-weight:600;
        }
        img{
            width:326px;
        }
    }
}
.staff-content{
  background:linear-gradient(to bottom, #FFF3F1, #FFCAC1);
  position: relative;
  overflow: hidden;
  padding-bottom: 60px;
  .bg{
    width:100%;
    position:absolute;
    bottom:-106px;
    left:50%;
    transform: translateX(-50%);
  }
  .staff-content-container{
    width:1220px;
    margin:0 auto;
    .module5{
        box-shadow: 0px 2px 10px 0px rgba(239,215,215,0.5);
        border-radius:15px;
        padding:45px;
        background:#fff;
        margin-bottom:48px;
        margin-top:50px;
        .title{
            font-size:22px;
            line-height:35px;
            color:#353535;
            font-weight:500;
        }
      .module5-content{
        img{
          width:486px;
        }
      }
      .module5-content-right{
          margin-top:20px;
        padding:40px 0 40px 46px;
        background: linear-gradient( 180deg, #FFF5F3 1%, #FFDAD1 100%);
        .item{
            display:flex;
            align-items:center;
            margin-bottom:25px;
        }
        p.title-item{
            color:#fff;
            text-align:center;
            border-radius:24px;
            background:$WDPrimaryColor;
            font-size:18px;
            width:98px;
            line-height:32px;
            font-weight:500;
            margin-right:24px;
        }
        p.content-item{
          color:#353535;
          padding-left: 24px;
          font-size:18px;
          line-height: 25px;
        }
      }
    }
    .module6{
        background:url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/staff/other-2-1.png') no-repeat center bottom;
        background-size:cover;
        margin-bottom:64px;
        border-radius:15px;
        overflow:hidden;
        .title{
            font-size:24px;
            font-weight:500;
            line-height:42px;
            padding:60px 48px 80px;
            
        }
    }
  }
}
</style>

