<template>
  <div class="staff">
    <div class="staff-banner">
      <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/staff/staff-banner.png"> 
      <div class="handle-btn">
        <button @click="toLogin">政府/协会管理人员注册/登录</button>
        <button @click="goAdmin">政府/协会管理人员试用</button>
      </div>
    </div>
    <div class="staff-content">
      <div class="staff-content-container">
        <div class="module1">
          <div class="module1-item">
            <div class="module1-item-left">
              <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/staff/m1-1.png"> 
            </div>
            <div class="module1-item-right">
              <p>山东应急全员定向安全培训</p>
              <div>
                <button @click="toView">查看培训课程</button>
                <button @click="toJump('/staff/orienteering')">详情介绍</button>
              </div>
            </div>
          </div>
          <div class="module1-item">
            <div class="module1-item-left">
              <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/staff/m1-2.png"> 
            </div>
            <div class="module1-item-right">
              <p>广西工业风险管理与防范能力建设项目</p>
              <div>
                <button @click="toJump('/staff/other')">详情介绍</button>
              </div>
            </div>
          </div>
        </div>
        <div class="module2 modules">
          <p class="title">企业注册，组织员工扫码，通过手机或电脑参加培训</p>
          <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/staff/line.png" class="line"> 
          <div class="module2-content">
            <div class="module2-left module2-item">
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/staff/m2-1.png"> 
            <p>企业申请</p>
          </div>
          <div class="module2-right module2-item">
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/staff/m2-2.png"> 
            <p>员工扫码，进入手机/电脑参加学习</p>
          </div>
          </div>
        </div>
        <div class="module3 modules">
          <p class="title">人脸识别，保证本人参培</p>
          <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/staff/line.png" class="line"> 
          <div class="module3-content">
            <p>参加考试或参加培训时，系统通过人脸识别校验当前学员身份；<br/>同时系统支持记录人脸识别截图，用于数据留存及学习监管。</p>
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/staff/m3-1.png"> 
          </div>          
        </div>
          <div class="module4 modules">
          <p class="title">培训过程实时监管</p>
          <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/staff/line.png" class="line"> 
          <div class="module4-content">
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/staff/m4-1.png"> 
          </div>          
        </div>
          <div class="module5 modules">
          <p class="title">开展考试，检查培训效果：千人千卷</p>
          <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/staff/line.png" class="line"> 
          <div class="module5-content">
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/staff/m5-1.png">
            <div class="module5-content-right">
              <div class="content-item">
                <p class="title-item"><i></i>随机组卷：</p>
                <p class="content-item">随机从指定题库抽题每位考生，题目不同。</p>
              </div>
              <div class="content-item">
                <p class="title-item"><i></i>顺序随机：</p>
                <p class="content-item">试卷设计题型、题目乱序，每道题出现顺序不同。</p>
              </div>
              <div class="content-item">
                <p class="title-item"><i></i>选项随机：</p>
                <p class="content-item">打乱选择题类试题选项顺序，ABCD答案人人不同。</p>
              </div>
            </div>
          </div>
        </div>
        <div class="module6 modules">
          <p class="title">生成学习档案：区块链技术防信息篡改</p>
          <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/staff/line.png" class="line"> 
          <div class="module6-content">
            <div class="text">
              <p><i></i>通过手机扫码查看个人学习档案 个人学习档</p>
              <p><i></i>个人学习档案中可查看每次进入课程时的人脸<br/>识别信息</p>
            </div>
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/staff/m6-1.png"> 
          </div>
        </div>
      </div>
      <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/staff/staff-bg.png" class="bg"> 
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="640px"
      :before-close="handleClose">
        <div class="loginDialog"><fe-login-register activeName="govt" :tabsList="tabsList"></fe-login-register></div>
    </el-dialog>
  </div>
</template>
<script>
import feLoginRegister from '@/modules/entrance/components/fe-login-register'
import {jumpTry} from '@/utils/getBranchDomain.js'

export default {
  data(){
    return{
      dialogVisible:false,
      tabsList: [
        { key: "govt", val: "我是政府/协会管理者" },
      ]
    }
  },
  components:{
    feLoginRegister
  },
  methods:{
    toView(){
      window.open('https://dxpx.wdeduc.com/','_blank');
    },
    toJump(path){
      this.$router.push({
        path:path
      })
    },
    handleClose(){
      this.dialogVisible = false;
    },
    toLogin(){
      this.dialogVisible = true;
    },
    goAdmin(){
      jumpTry('govt')
    },
  }
}
</script>
<style lang="scss" scoped>
.staff{
  min-width:1360px;
}
::v-deep .el-dialog__header{
  padding:0;
}
::v-deep .el-dialog{
  border-radius:16px;
}
.loginDialog{
  ::v-deep button{
    margin: 0 auto 36px;
    display:block;
  }
}
.staff-banner{
  position:relative;
  img{
    width: 100%;
  }
  .handle-btn{
    bottom:110px;
    position:absolute;
    left:50%;
    transform:translateX(-50%);
    display:flex;
      button{
        background: linear-gradient( 180deg, #FFF6DF 2%, #F5B957 100%);
        width:308px;
        text-align:center;
        height:54px;
        line-height:54px;
        color:#fff;
        display:block;
        border:none;
        outline:none;
        font-size:18px;
        font-weight:600;
        border-radius:32px;
        color:$WDPrimaryColor;
        margin:0 auto;
        transition: all .6s ease;
        cursor:pointer;
        &:nth-child(2){
          margin-left:100px;
        }
        &:hover{
          opacity:.8;
        }
    }
  }
}
.staff-content{
  background:linear-gradient(to bottom, #fff, #FFCAC1);
  position: relative;
  overflow: hidden;
  .bg{
    width:100%;
    position:relative;
    z-index:2;
    margin-top:-300px;
  }
  .staff-content-container{
    width:1220px;
    margin:0 auto;
    z-index:3;
    position:relative;
    .module1{
      margin-top: 50px;
      margin-bottom: 42px;
      overflow:hidden;
      height:256px;
      box-sizing: border-box;
      background: #fff;
      padding: 38px 0 0 40px;
      box-shadow: 0px 2px 10px 0px rgba(239,215,215,0.5);
      border-radius: 15px;
      display:flex;
    }
    .module1-item{
      display:flex;
      &:nth-child(1){
        width:720px;
        .module1-item-right{
          padding-right: 0;
        }
      }
      .module1-item-right{
        padding-top:26px;
        padding-right:24px;
      }
      p{
        color: #343434;
        font-size: 22px;
        height: 52px;
        line-height: 26px;
        font-weight: 500;
        margin-bottom: 42px;
      }
      img{
        width: 256px;
        margin-right: 16px;
      }
      button{
        outline: none;
        border:none;
        color:#fff;
        background:$WDPrimaryColor;
        padding:6px 18px;
        border-radius:24px;
        font-weight: 500;
        font-size: 18px;
        line-height: 25px;
        &:hover{
          opacity: .6;
          cursor: pointer;
        }
        &:nth-child(1){
          margin-right: 20px;
        }
      }
    }
    .line{
      width:907px;
      display: block;
      margin:0 auto 46px;
    }
    .title{
      font-size: 28px;
      line-height: 40px;
      color:#343434;
      text-align:center;
      padding:40px 0 12px;
      font-weight: 600;
    }
    .module2{
      background: #fff;
      padding:0 42px;
      .module2-content{
        display: flex;
        justify-content: space-between;
      }
      .module2-left{
        img{
          width:464px;
        }
      }
      .module2-right{
        img{
          width:620px;
        }
      }
      .module2-item{
        p{
          font-size:22px;
          line-height: 27px;
          color:#D10000;
          font-weight: 500;
          text-align: center;
          margin-top: 18px;
        }
      }
    }
    .modules{
      background: #fff;
      padding:0 42px;
    }
    .module3{
      .module3-content{
        position: relative;
        img{
          width:100%;
        }
        p{
          color:#353535;
          font-weight: 500;
          font-size: 20px;
          line-height: 32px;
          position: absolute;
          top:42px;
          right:56px;
        }
      }
    }
    .module4{
      .module4-content{
        img{
          width:100%;
        }
       
      }
    }
    .module5{
      .module5-content{
        display: flex;
        img{
          width:486px;
        }
      }
      .module5-content-right{
        margin-left:40px;
        padding-top:24px;
        i{
          display: inline-block;
          width: 12px;
          height:12px;
          border:2px solid #D10000;
          margin-right:16px;
        }
        p.title-item{
          color:$WDPrimaryColor;
          font-size:22px;
          line-height: 35px;
          font-weight:500;
        }
        p.content-item{
          color:#353535;
          padding-left: 32px;
          font-size:22px;
          line-height: 35px;
          font-weight:500;
          margin-bottom:35px;
        }
      }
    }
    .module6{
      border-radius: 0 0 16px 16px;
      .module6-content{
        position: relative;
        text-align:center;
        img{
          width:1092px;
        }
        .text{
          position: absolute;
          top:18px;
          left:325px;
          p{
            font-size:22px;
            line-height:35px;
            font-weight:500;
            color:#353535;
            padding-left: 27px;
            text-align:left;
            position: relative;
          }
          i{
            width: 11px;
            height: 11px;
            background: $WDPrimaryColor;
            display: inline-block;
            border-radius: 50%;
            margin-right:16px;
            position: absolute;
            left:0;
            top:12px;
          }
        }
      }
    }
  }
}
</style>

